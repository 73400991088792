import React, { useState } from "react";
import "../css/productCenter.css";
import { useNavigate } from "react-router-dom";

type ProductItemData = {
  key: number;
  image: string;
  itemcase: string;
  title: string;
  describe: string;
  isImage?: boolean;
  itemRoute?: any;
};

const ProductCenter: React.FC = (props: { isProductPage: boolean, routeIndex: number }) => {
  const idx = !props.isProductPage ? 0 : props.routeIndex
  const [index, setIndex] = useState(idx);
  const navigate = useNavigate();
  const onChange = (index: number) => {
    setIndex(index);
  };

  const productItemData = [
    {
      key: 0,
      image: "./images/home/yanglao.webp",
      itemcase: "养老案例",
      title: "智慧家庭数智管家AI机器人——小数",
      describe:
        "小数是一款与华数中广联合研发的智慧家庭管家AI全息仓。该产品采用全息仓结构+AI虚拟人的设计，为老年人居家养老场景下的各项服务",
      isImage: true,
      itemRoute: "/xiao_shu",
    },
    {
      key: 1,
      image: "./images/home/wenlv.webp",
      itemcase: "服务案例",
      title: "文旅讲解员",
      describe:
        "AI文物导览讲解员是一款基于人工智能技术的前沿虚拟导览解决方案，专注于文物及展品的价值传播+AI虚拟人的设计，为老年人居家养老场景下的各项服务",
      isImage: true,
      itemRoute: "/ai_presenter",
    },
    {
      key: 2,
      image: "./images/home/aiyuyin.webp",
      itemcase: "服务案例",
      title: "AI语音协商机器人",
      describe:
        "AI语音协商机器人是一款基于人工智能技术的前沿虚拟客服解决方案，专为电话营销、客户回访等场景设计",
      isImage: true,
      itemRoute: "/ai_voice",
    },
    {
      key: 3,
      image: "",
      itemcase: "",
      title: "",
      describe: "",
      isImage: false,
    },
    {
      key: 4,
      image: "./images/home/daolan.webp",
      itemcase: "服务案例",
      title: "导览讲解员",
      describe:
        "AI文物导览讲解员是一款基于人工智能技术的前沿虚拟导览解决方案，专注于文物及展品的价值传播",
      isImage: true,
      itemRoute: "/ai_presenter",
    },
    {
      key: 5,
      image: "./images/home/aijiaoyu.webp",
      itemcase: "教育案例",
      title: "AI教育智能体",
      describe:
        "智慧家庭教育新场景——AI教育智能体是一款创新的教育智能助手，利用前沿的AI技术，通过多模态数据和多元信息关联分析，提供个性化和智能化的学习辅导",
      isImage: true,
      itemRoute: "/ai_educational",
    },
    {
      key: 6,
      image: "./images/home/liangzhushiyan.webp",
      itemcase: "服务案例",
      title: "良渚实验室AI细胞培养预测工程师",
      describe:
        "良渚实验室AI细胞培养预测工程师是一款通过先进的人工智能算法，AI预测功能能够对实验室的细胞培养情况进行精准预测和实时检测，保障细胞存活率",
      isImage: true,
      itemRoute: "/ai_cell",
    },
  ];

  const CooperateData = [
    {
      key: 0,
      image: "./images/home/bosideng.webp",
      itemcase: "服务案例",
      title: "波司登创意系统平台",
      describe:
        "智能创意系统是一款面向设计人员的综合性创意平台，提供从灵感图库到智能成衣、图案生成等全流程设计支持",
      isImage: true,
      itemRoute: "/bsd",
    },
    {
      key: 1,
      image: "",
      itemcase: "",
      title: "",
      describe: "",
      isImage: false,
    },
    {
      key: 2,
      image: "./images/home/mbti.webp",
      itemcase: "服务案例",
      title: "基于MBTI人格特征的智能车衣定制工具",
      describe: `智能车衣定制工具是一款创新的智能车衣定制工具，它基于MBTI（ Myers-Briggs Type Indicator）人格特征，为车主提供个性化的车衣设计方案`,
      isImage: true,
      itemRoute: "/mbti",
    },
  ];

  return (
    <div className="product-container">
      <div className="line"></div>
      <div className={`title ${props.isProductPage ? "title-product" : ""}`}>
        <span>产品中心</span>
        {!props.isProductPage ? (
          <button
            onClick={() => {
              navigate('/products');
            }}
          >
            <div>
              <span>所有产品</span>
            </div>
            <div
              className="icon-chevron"
              style={{
                width: "calc(35 / var(--auto-width))",
                height: "calc(35 / var(--auto-width))",
              }}
            >
              <div>
                <img src="./images/home/left_small_up.webp" alt="arrow" />
              </div>
              <div className="copy">
                <img src="./images/home/left_small_up.webp" alt="arrow" />
              </div>
            </div>
          </button>
        ) : null}
      </div>
      <div className="tab">
        <button
          onClick={() => onChange(0)}
          className={index === 0 ? "actived-button" : "notactived-button"}
        >
          产品
        </button>
        <button
          onClick={() => onChange(1)}
          style={{ marginLeft: "calc(40 / var(--auto-width))" }}
          className={index === 1 ? "actived-button" : "notactived-button"}
        >
          其他合作
        </button>
      </div>
      <hr />
      <div className={[index === 0 ? "item-container" : "item-container1"]}>
        {index === 0 ? (
          <ProductContainer data={productItemData} itemIndex={index} />
        ) : (
          <ProductContainer data={CooperateData} itemIndex={index} />
        )}
      </div>
    </div>
  );
};

const ProductItem = (props: {
  itemProps: ProductItemData;
  key: number;
  itemIndex: number;
}) => {
  const { key, image, itemcase, title, describe, itemRoute } = props.itemProps;
  const { itemIndex } = props;
  const navigate = useNavigate();
  return (
    <div
      key={itemIndex}
      className={[
        itemIndex === 0 && key === 4
          ? "product-item margin-top"
          : "product-item",
      ]}
    >
      <img
        src={image}
        alt={itemcase + "图片"}
        className={[
          itemIndex === 1 && key === 2
            ? "anli-image margin-top1"
            : "anli-image",
        ]}
      />
      <span>{itemcase}</span>
      <div className="zhihui">
        <span>{title}</span>
        <button
          className="icon-chevron"
          onClick={() => {
            navigate(itemRoute);
          }}
        >
          <div>
            <img
              src="./images/home/left_small_up.webp"
              alt="arrow"
              className="arrow"
            />
          </div>
          <div className="copy">
            <img
              src="./images/home/left_small_up.webp"
              alt="arrow"
              className="arrow"
            />
          </div>
        </button>
      </div>
      <span>{describe}</span>
      {key === 2 || key === 6 || (itemIndex === 1 && key === 0) ? null : (
        <div className="product-item-line"></div>
      )}
    </div>
  );
};

const ProductSmallItem = () => {
  return (
    <div className="product-small-item">
      <span className="shijia">10+</span>
      <div className="column-line"></div>
      <span className="ai-item">已实施的AI项目</span>
    </div>
  );
};

const ProductContainer = (props: {
  data: ProductItemData[];
  itemIndex: number;
}) => {
  const { data, itemIndex } = props;
  return (
    <>
      {data.map((item: ProductItemData, index) => {
        return (
          <>
            {item.isImage ? (
              <ProductItem
                itemProps={item}
                key={index}
                itemIndex={itemIndex}
              ></ProductItem>
            ) : (
              <ProductSmallItem></ProductSmallItem>
            )}
          </>
        );
      })}
    </>
  );
};

export default ProductCenter;
