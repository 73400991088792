import React, { useState, useRef } from "react";
import { Carousel } from "antd";
import "../css/productCarousel.css";
interface ProductCarouselProps {
  imageData: string[];
  contentData: string[];
}

const ProductCarousel = (props: ProductCarouselProps) => {
  const { imageData, contentData } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null); // 使用 ref 绑定 Carousel

  // 切换
  const handleBackgroundChange = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % imageData.length;
      // 同步更新 Carousel 的当前 slide
      if (carouselRef.current) {
        (carouselRef.current as any).goTo(newIndex);
      }
      return newIndex;
    });
  };

  return (
    <Carousel
      ref={carouselRef} // 绑定 Carousel 的 ref
      effect="fade"
      infinite={false}
      className="product-carousel-text"
      afterChange={(current) => {
        setCurrentIndex(current);
      }}
    >
      {contentData.map((text, index) => (
        <div
          className={`height product-carousel-item ${imageData[index]}`}
          key={index}
          onClick={handleBackgroundChange}
        >
          {index === 0 ? (
            <div className="product-carousel-item-span">
              <span className="product-carousel-item-text">
                Application scenarios
              </span>
              <div className="product-carousel-item-div">应用场景</div>
            </div>
          ) : null}

          <span
            className={`product-carousel-item-text product-carousel-item-hover ${
              index !== 0 ? "product-carousel-item-hover1" : ""
            }`}
          >
            {text}
          </span>
        </div>
      ))}
    </Carousel>
  );
};

export default ProductCarousel;
