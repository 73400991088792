import React from 'react'

const ProductDetailImage: React.FC = (props: { imageUrl: string }) => {
    return (
        <div style={{ width: '100%' }} className='height'>
            <img src={props.imageUrl} alt="" style={{ width: '100%', height: '100%' }} />
        </div>
    )
}

export default ProductDetailImage