import React from "react";
import ProductCenter from "../home/homeComponents/ProductCenter.tsx";
import NavHeaders from "../navHeader/NavHeader.tsx";
import "./css/products.css";
import Footer from "../footer/Footer.tsx";
import { useScrollToTop } from "../../App.tsx";

const Products1: React.FC = () => {
    useScrollToTop();

    return (
        <div className="products-background">
            <NavHeaders />
            <div className="container products-container">
                <ProductCenter isProductPage={true} routeIndex={1} />
            </div>
            <Footer />
        </div>
    );
};

export default Products1;
