import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/aiEducational.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarouselItem from "../productsComponents/ProductCarouselItem.tsx";
import { useScrollToTop } from "../../../App.tsx";

const AIEducational: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "AI educational intelligent agent",
    text2: "",
    text3: "AI教育智能体",
    btnItem: [
      {
        key: 0,
        text: "GPT大语言模型驱动",
      },
      {
        key: 1,
        text: "AI Agent赋能智能教育",
      },
      {
        key: 2,
        text: "灵活适配",
      },
      {
        key: 3,
        text: "智能反馈",
      },
    ],
  };
  const techData = [
    {
      image: "./images/products/microchip-ai.svg",
      text1: "GPTCall大模型驱动",
      text2: "通过自然语言处理和分析学习对象的表达，提供个性化的教育方案。",
    },
    {
      image: "./images/products/grid-masonry.svg",
      text1: "AI Agent赋能智能教育",
      text2: "整合多种AI模型，实现知识图谱、个性化学习以及多轮交互。",
    },
    {
      image: "./images/products/lightbulb-brain.svg",
      text1: "灵活适配",
      text2:
        "针对不同学生的学习特点，无缝适配个性化需求，支持Prompt精准生成学习内容。",
    },
    {
      image: "./images/products/double-check-square.svg",
      text1: "智能反馈",
      text2:
        "提供数据驱动的学习评价和及时反馈，帮助学生发现学习中的问题和进步。",
    },
  ];
  const itemData = {
    title: "educational",
    text: "课外教育场景：帮助学生完成课后作业及复习任务，解决学生在家庭教育中的疑难点，提升学习效果",
  };
  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container ai-educational-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height ai-educational-product">
        <div className="ai-educational-product-container">
          <div className="ai-educational-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              拟人实时交互——AI电话客服助手是一款基于人工智能技术的前沿虚拟客服解决方案，专为电话营销、客户回访等场景设计。通过模拟真人互动，该系统能够提供个性化的多轮对话服务，提升客户沟通体验并提高企业服务效率。AI客服助手运用先进的AI技术，以虚拟助手的形式进行客户引导和问题解答，实现自然、流畅的交流效果。
            </span>
          </div>
          <div className="ai-educational-product-right">
            <img src="./images/products/ai_educational_pro.webp" alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="ai-educational-product-back" />
      </div>
      <ProductDetailImage imageUrl="./images/products/ai_educational_image1.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarouselItem item={itemData} />
      <Footer />
    </div>
  );
};

export default AIEducational;
