import React, { useRef, useEffect } from "react";
import "../css/productTechnology.css";

const ProductTechnology: React.FC = (props: { techData: any }) => {
  const { techData } = props;
  const boxWidth =
    techData.length === 4
      ? "calc(810 / var(--auto-width))"
      : "calc(1220 / var(--auto-width))";
  const itemLength = techData.length;
  const productTechnologyRef = useRef<HTMLDivElement | null>(null);
  const boxRefs = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];

  useEffect(() => {
    const handleScroll = () => {
      boxRefs.forEach((ref, index) => {
        if (ref.current) {
          const productItem =
            productTechnologyRef.current.getBoundingClientRect();
          const offsetTop = productItem.top + window.scrollY;
          // Get the distance from the top of the document
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          if (scrollTop >= offsetTop - 200) {
            // Adjust the value as needed
            setTimeout(() => {
              ref.current?.classList.add("animate");
            }, index * 300); // Delay each animation by 300ms
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="height product-technology" ref={productTechnologyRef}>
      <img
        src="./images/products/technology_back.webp"
        alt=""
        className="technology_back"
      />
      <div
        className="product-technology-box-container hidden"
        style={{ maxWidth: boxWidth }}
      >
        {techData.map((item: any, index: number) => (
          <div
            ref={boxRefs[index]}
            className="product-technology-box"
            key={index}
          >
            <Item item={item} index={index} itemLength={itemLength}></Item>
          </div>
        ))}
      </div>
    </div>
  );
};

const Item = (props: { item: any; index: number; itemLength: number }) => {
  const { image, text1, text2 } = props.item;
  const { index, itemLength } = props;
  const fourItem = index >= 2 ? "calc(74 / var(--auto-width))" : 0;
  const fiveItem = index >= 3 ? "calc(74 / var(--auto-width))" : 0;
  return (
    <div
      key={props.index}
      style={{
        marginTop:
          itemLength !== 3 ? (itemLength === 4 ? fourItem : fiveItem) : 0,
      }}
    >
      <img src={image} alt="" style={{ width: "calc(60 / var(--auto-width))", height: "calc(60 / var(--auto-width))" }} />
      <div className="product-technology-text">
        <span>{text1}</span>
        <span>{text2}</span>
      </div>
    </div>
  );
};

export default ProductTechnology;
