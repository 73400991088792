import React from "react";
import "../css/carousel.css";

const Carousel: React.FC = (props: { data; index }) => {
  return (
    <div className={`home-carousel ${props.index ? "home-carousel3" : ""}`}>
      <div className="scroll-content">
        {props.data.map((imageUrl, index) => (
          <div key={index} className="scroll-item">
            <img src={`./images/home/${imageUrl}`} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
