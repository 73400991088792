// export const items1 = [
//   "zheyi.webp",
//   "guodian.webp",
//   "hangdian.webp",
//   "zhongguang.webp",
//   "feizhi.webp",
//   "zhongguo.webp",
//   "zheyi.webp",
//   "guodian.webp",
//   "hangdian.webp",
//   "zhongguang.webp",
//   "feizhi.webp",
//   "zhongguo.webp",
// ];

// export const items2 = [
//   "zhongguomeishu.webp",
//   "zheke.webp",
//   "liangzhu.webp",
//   "huashu.webp",
//   "zhegong.webp",
//   "wufangzhai.webp",
//   "zhongguomeishu.webp",
//   "zheke.webp",
//   "liangzhu.webp",
//   "huashu.webp",
//   "zhegong.webp",
//   "wufangzhai.webp",
// ];

// export const items3 = [
//   "zheyuan.webp",
//   "lvcheng.webp",
//   "zheji.webp",
//   "zheda.webp",
//   "cc.webp",
//   "quanjin.webp",
//   "zheyuan.webp",
//   "lvcheng.webp",
//   "zheji.webp",
//   "zheda.webp",
//   "cc.webp",
//   "quanjin.webp",
// ];

export const line1 = ["line1.webp", "line1.webp"];
export const line2 = ["line2.webp", "line2.webp"];
export const line3 = ["line3.webp", "line3.webp"];
