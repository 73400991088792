import React from "react";
import { useNavigate } from "react-router-dom";
import NavHeaders from "../navHeader/NavHeader.tsx";
import "./css/home.css";
import ProductCenter from "./homeComponents/ProductCenter.tsx";
import Carousel from "./homeComponents/Carousel.tsx";
import Footer from "../footer/Footer.tsx";
import { useScrollToTop } from "../../App.tsx";
import { line1, line2, line3 } from "./data.ts";
import CarouselToRight from "./homeComponents/CarouselToRight.tsx";

const Home = () => {
  const navigate = useNavigate();
  useScrollToTop();

  return (
    <div className="home-container">
      <NavHeaders />
      {/* video background */}
      <div className="video-background ">
        <img
          src="./images/home/video-back.webp"
          alt=""
          className="video-back"
        />
        <video autoPlay loop muted style={{ objectFit: "fill" }}>
          <source
            src="./videos/banner-av1.mp4"
            type="video/mp4; codecs=av01.0.05M.08"
          />
          <source
            src="./videos/banner-h265.mp4"
            type="video/mp4; codecs=hevc,mp4a.40.2"
          />
          <source
            src="./videos/banner-h264.mp4"
            type="video/mp4; codecs=avc1.42E01E,mp4a.40.2"
          />
          您的浏览器不支持HTML5视频！
        </video>
        <div className="container banner-container">
          <span className="alphaMan">AlphaMan</span>
        </div>
        <div className="banner-title">
          <div className="invisible">
            <span>Educational AI</span>
          </div>
          <div className="hero_cubespinner">
            <div className="hero1">
              <span>Guide AI</span>
            </div>
            <div className="hero2">
              <span>Educational AI</span>
            </div>
            <div className="hero3">
              <span>Elderly care AI</span>
            </div>
          </div>
        </div>
        <div className="container">
          <span className="infuse">
            Infuse the temperature of AI into life with technology
          </span>
        </div>
        <div className="container explore">
          <img src="./images/home/explore.webp" alt="向下滑动"></img>
        </div>
        <div className="container company-position">
          <div className="company-box">
            <div className="line"></div>
            <div className="company-title">
              <span>公司定位</span>
              <span>AGI数智人解决方案先行者</span>
            </div>
            <span className="introduction">
              杭州像素跃迁科技有限公司专注于AGI技术及数智人领域研发，立足余杭，服务长三角及全球。核心业务涵盖一站式数智人定制服务、技能热更新引擎和EmotionChat模型，致力于打造高情商、强互动、沉浸式数智交互服务，以科技赋予生活AI温度。
            </span>
          </div>
        </div>
      </div>
      <div className="home-background">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="deep">
            <span>深入了解我们的信息</span>
            <button
              onClick={() => {
                navigate("/company_introduction");
              }}
            >
              <div
                className="icon-chevron"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "calc(22 / var(--auto-width))",
                }}
              >
                <div>
                  <img src="./images/home/left_small_up.webp" alt="arrow" />
                </div>
                <div className="copy">
                  <img src="./images/home/left_small_up.webp" alt="arrow" />
                </div>
              </div>
            </button>
          </div>
        </div>
        <Carousel data={line1} index={0} />
        <CarouselToRight data={line2} />
        <Carousel data={line3} index={1} />
        <div className="container">
          <ProductCenter isProductPage={false} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
