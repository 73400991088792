import React, { useRef, useEffect } from "react";
import "./css/core.css";
import NavHeader from "../navHeader/NavHeader.tsx";
import { BottomLine } from "../common/BottomLine.tsx";
import Footer from "../footer/Footer.tsx";
import { useScrollToTop } from "../../App.tsx";

const CoreTechnology: React.FC = () => {
  useScrollToTop();
  const coreBackRef = useRef<HTMLDivElement | null>(null);
  const boxRefs = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];
  const coreBackItemData = [
    {
      span1: "01",
      span2: "行业趋势",
      text: "大语言模型技术的快速发展推动了AGI数智人浪潮的涌现，市场规模持续增长，预计2026年我国虚拟数字人行业规模将突破1200亿。",
    },
    {
      span1: "02",
      span2: "市场需求",
      text: "企业数智建设需求庞大，但存在上游数据驱动不足、人机交互低智能等痛点，导致市场供需不匹配。",
    },
    {
      span1: "03",
      span2: "政策推动",
      text: "国家六大部门、五大相关政策支持人工智能产业发展，促进了智能化、普惠化趋势。",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      boxRefs.forEach((ref, index) => {
        if (ref.current) {
          const productItem = coreBackRef.current.getBoundingClientRect();
          const offsetTop = productItem.top + window.scrollY;
          // Get the distance from the top of the document
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          if (scrollTop >= offsetTop - 600) {
            // Adjust the value as needed
            setTimeout(() => {
              ref.current?.classList.add("core-animate");
            }, index * 300); // Delay each animation by 300ms
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="core-container">
      <NavHeader></NavHeader>
      <div className="core-header height">
        <div className="core-header-container">
          <div className="core-header-box">
            <div className="core-header-text">
              <div className="line"></div>
              <span>AlphaOS数字人驱动引擎</span>
            </div>
            <span className="alphaOS">AlphaOS Digital Human Driver Engine</span>
          </div>
        </div>
      </div>
      <div className="core-content">
        <div className="core-back-container">
          <div className="core-header-text">
            <div className="line"></div>
            <span>背景</span>
          </div>
          <div className="core-background" ref={coreBackRef}>
            {coreBackItemData.map((item, index) => (
              <div key={index} ref={boxRefs[index]} className="core-back-item">
                <div className="core-back-text">
                  <span>{item.span1}</span>
                  <span>{item.span2}</span>
                </div>
                <span className="core-back-span">{item.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="core-tech-container">
          <div className="core-tech-title">技术积累</div>
          <BottomLine />
          <div className="core-tech-text">
            公司自研三大核心功能，创新AI驱动数智人开发工作流，包括热技能烘焙引擎IDE开发工具（&nbsp;&nbsp;&nbsp;实现低代码高模块化引擎提升75%开发效率，满足企业端数智人机交互系统需求。）、GPTCall（&nbsp;&nbsp;&nbsp;GPTCall调用大模型处理用户自然语言，实现低成本技能调用）和EmotionChat情感插件（&nbsp;&nbsp;&nbsp;基于自研的SSR小模型，与大语言模型耦合微调，实现了多元情绪的注入，使数字人在情感交互中表现更真实、细腻。通过收集大量群体情绪数据，构建情绪数据库，形成数据资源壁垒。）
          </div>
          <img src="./images/core/core_tech.webp" alt="" />
        </div>
        <div className="core-app-container">
          <div className="core-tech-title">应用场景</div>
          <BottomLine />
          <div className="core-app-box">
            <div className="core-app-item">
              <img src="./images/core/finance.webp" alt="" />
              <span>金融协商场景</span>
            </div>
            <div className="core-app-item">
              <img src="./images/core/park.webp" alt="" />
              <span>园区AI管家生活服务场景</span>
            </div>
            <div className="core-app-item">
              <img src="./images/core/pension.webp" alt="" />
              <span>养老服务场景</span>
            </div>
            <div className="core-app-item">
              <img src="./images/core/teenager.webp" alt="" />
              <span>青少年教育场景</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoreTechnology;
