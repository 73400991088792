import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/aiPresenter.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarousel from "../productsComponents/ProductCarousel.tsx";
import { useScrollToTop } from "../../../App.tsx";

const AIPresenter: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "AI presenter",
    text2: "",
    text3: "AI讲解员",
    btnItem: [
      {
        key: 0,
        text: "AI语音识别与处理",
      },
      {
        key: 1,
        text: "模块化系统",
      },
      {
        key: 2,
        text: "精准定位服务",
      },
      {
        key: 3,
        text: "情感交互",
      },
    ],
  };
  const techData = [
    {
      image: "./images/products/microphone-sparkle.svg",
      text1: "AI语音识别与处理",
      text2:
        "采用先进AI模型，准确识别语音，实现流畅对话交流，帮助访客更好理解展品价值。",
    },
    {
      image: "./images/products/puzzle.svg",
      text1: "模块化系统",
      text2: "采用模块化设计，支持高效安装和快速部署，适用于多种室内外场景。",
    },
    {
      image: "./images/products/location-crosshairs.svg",
      text1: "精准定位服务",
      text2:
        "自研导航算法结合标定点技术，拟人化指路体验，让用户获得更亲切、直观导航服务。",
    },
    {
      image: "./images/products/heartbeat.svg",
      text1: "情感交互",
      text2:
        "情感反馈技术，提供更贴近真人交互体验，使导览员更具人性化，提升访客舒适度。",
    },
  ];
  const imageData = [
    "ai-presenter-product-carousel-container1",
    "ai-presenter-product-carousel-container2",
    "ai-presenter-product-carousel-container3",
  ];

  const contentData = [
    "景区和园区导览：AI导览讲解员能够为访客提供详细的路线指引、景点介绍和行程规划，提升整体参观体验，帮助游客更好地了解园区特色",
    "博物馆与展览馆导览：AI文物导览讲解员能够详细介绍文物的背景、价值和艺术特色，提供路线规划，提升参观体验，帮助访客更好地理解和欣赏展品",
    "商业综合体和大型建筑：AI导览讲解员能够协助访客快速找到所需位置，同时提供商店和设施的介绍，优化购物或参观体验，增强场所的服务能力",
  ];

  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container ai-presenter-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height ai-presenter-product ai-presenter-product1">
        <div className="container1200 ai-presenter-product-container">
          <div className="ai-presenter-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              AI导览讲解员是一款基于人工智能的虚拟导览助手，专注于为园区、景区和大型场所提供智能化的路线指引和即时化导览服务。系统以虚拟助手的形式，引导访客完成路线规划、导航和信息讲解，提升访客体验并优化信息传播效果。
            </span>
          </div>
          <div className="ai-presenter-product-right">
            <img src="./images/products/ai_housekeeper.webp" alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="ai-presenter-product-back" />
      </div>
      <div className="height ai-presenter-product">
        <div className="container1200 ai-presenter-product-container ai-presenter-product-container1">
          <img
            src="./images/products/ai_presenter_product_back1.webp"
            alt=""
            className="ai-presenter-product-back1"
          />
          <div className="ai-presenter-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              AI文物导览讲解员是一款基于人工智能技术的前沿虚拟导览解决方案，专注于文物及展品的价值传播。该方案通过模拟真人互动，为博物馆、展览馆等场景提供个性化和即时化的导览服务。系统以虚拟助手的形式，结合AI技术，为访客提供深入的文物解读，传递文物背后的历史、文化和艺术价值。
            </span>
          </div>
          <div
            className="ai-presenter-product-right"
            style={{ position: "relative" }}
          >
            <img src="./images/products/ai_culture.webp" alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="ai-presenter-product-back" />
      </div>
      <div className="height ai_image">
        <img src="./images/products/ai_left.webp" alt="" />
        <img src="./images/products/ai_right.webp" alt="" />
      </div>
      <ProductTechnology techData={techData} />
      <ProductCarousel imageData={imageData} contentData={contentData} />
      <Footer />
    </div>
  );
};

export default AIPresenter;
