import React, { useRef, useEffect } from "react";
import NavHeader from "../navHeader/NavHeader.tsx";
import "./css/companyProfile.css";
import { BottomLine } from "../common/BottomLine.tsx";
import CompanyCarousel from "./companyProfileComponents/CompanyCarousel.tsx";
import MediaPublicity from "./companyProfileComponents/MediaPublicity.tsx";
import Footer from "../footer/Footer.tsx";
import { useScrollToTop } from "../../App.tsx";

const CompanyProfile: React.FC = () => {
  useScrollToTop();
  const devBoxRef = useRef<HTMLDivElement | null>(null);
  // 创建对目标元素的引用
  const targetCultureRef = useRef<HTMLDivElement>(null);
  const targetRongyuRef = useRef<HTMLDivElement>(null);
  const targetDevRef = useRef<HTMLDivElement>(null);
  const targetMediaRef = useRef<HTMLDivElement>(null);

  // 滚动到目标元素的函数
  const scrollToCulture = (): void => {
    if (targetCultureRef.current) {
      targetCultureRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToRongyu = (): void => {
    if (targetRongyuRef.current) {
      targetRongyuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToDev = (): void => {
    if (targetDevRef.current) {
      targetDevRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToMedia = (): void => {
    if (targetMediaRef.current) {
      targetMediaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const boxRefs = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];
  const devBoxItemData = [
    {
      year: "2022",
      month: "09",
      text: "杭州像素跃迁科技有限公司由浙江大学计算机学院设计学毕业生陈小雨创立，专注于人机交互设计相关科研与创新创业。",
    },
    {
      year: "2023",
      month: "12",
      text: "针对居家养老场景，研发以纯语音方式交互的AI机器人产品，消弭老年人数字鸿沟现象，目前已经在浙江绍兴地区落地推广。",
    },
    {
      year: "2024",
      month: "09",
      text: "探索自研AlphaOS系统在生活服务、生物医药、儿童教育等场景的应用，陆续与绿城物业、良渚实验室、华数传媒等公司建立合作。",
    },
    {
      year: "至今",
      month: "",
      text: "由B端服务开始探索C端产品...",
    },
  ];

  const cultureBoxRefs = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];
  const culturItemData = [
    {
      span1: "创新文化",
      span2: "INNOVATIVE CULTURE",
      imgUrl: './images/company/innovative_culture.webp'
    },
    {
      span1: "专注精神",
      span2: "FOCUSED SPIRIT",
      imgUrl: "./images/company/focused_spirit.webp",
    },
    {
      span1: "责任担当",
      span2: "ACCOUNTABILITY",
      imgUrl: "./images/company/account_ability.webp",
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      boxRefs.forEach((ref, index) => {
        if (ref.current) {
          const productItem = devBoxRef.current.getBoundingClientRect();
          const offsetTop = productItem.top + window.scrollY;
          // Get the distance from the top of the document
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          if (scrollTop >= offsetTop) {
            // Adjust the value as needed
            setTimeout(() => {
              ref.current?.classList.add("fanzhanlicheng-animate");
            }, index * 300); // Delay each animation by 300ms
          }
        }
      });
      cultureBoxRefs.forEach((ref, index) => {
        if (ref.current) {
          const productItem = targetCultureRef.current.getBoundingClientRect();
          const offsetTop = productItem.top + window.scrollY;
          // Get the distance from the top of the document
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          if (scrollTop >= offsetTop) {
            // Adjust the value as needed
            setTimeout(() => {
              ref.current?.classList.add("fanzhanlicheng-animate");
            }, index * 300); // Delay each animation by 300ms
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <NavHeader
        scrollToCulture={scrollToCulture}
        scrollToRongyu={scrollToRongyu}
        scrollToDev={scrollToDev}
        scrollToMedia={scrollToMedia}
      />
      <div className="height company-about">
        <div className="container company-about-container">
          <div className="company-about-text">
            <span>
              About
              <br />
              company
            </span>
          </div>
          <span className="company-about-xiangsu">
            杭州像素跃迁科技有限公司以余杭为根基，辐射长三角经济圈，面向全球市场，致力于成为引领未来的独角兽企业。公司专注于AGI（通用人工智能）技术及其在数智人领域的创新研发，聚焦于AI技术与实际场景的深度融合，立志用技术为生活注入温度。像素跃迁始终以技术创新为驱动，坚持科研与应用并重。公司团队深耕人工智能领域，致力于构建多模态数据处理和自适应算法框架，为用户提供高效、高质量的智能服务。通过构建数智人为载体，像素跃迁探索出了一条以科技温暖生活的独特道路。
          </span>
        </div>
      </div>
      <div className="company-container-background" ref={targetCultureRef}>
        <div className="company-culture">
          <div className="company-culture-title">企业文化</div>
          <BottomLine />
          <div className="company-culture-boxes">
            {
              culturItemData.map((item, index) => (
                <div className="company-culture-box" key={index} ref={cultureBoxRefs[index]}>
                  <img
                    src={item.imgUrl}
                    alt=""
                    className={`image${index + 1}`}
                  />
                  <span>{item.span1}</span>
                  <span>{item.span2}</span>
                </div>
              ))
            }
          </div>
          <div
            className="company-culture-title company-culture-title1"
            ref={targetRongyuRef}
          >
            荣誉资质
          </div>
          <BottomLine />
        </div>
        <CompanyCarousel />
        <div className="fazhanlicheng" ref={targetDevRef}>
          <div className="container fazhanlicheng-container" ref={devBoxRef}>
            <div className="fazhanlicheng-line"></div>
            <span className="fazhenglicheng-title">发展历程</span>
          </div>
          <div className="fazhanlicheng-box-back">
            {devBoxItemData.map((item, index) => {
              const { year, month, text } = item;
              return (
                <div className="fazhanlicheng-box" key={index}>
                  <img
                    src="./images/company/dev_line.svg"
                    alt=""
                    className="fanzhanlicheng-box-line"
                  />
                  <div ref={boxRefs[index]} className="fanzhanlicheng-text-box">
                    <div className="text-box">
                      <span className="fanzhanlicheng-text-year">{year}</span>
                      {month ? (
                        <div>
                          <span className="fanzhanlicheng-text-month">
                            {month}
                          </span>
                          <br />
                          <span className="fanzhanlicheng-text-years">
                            years
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <span className="fazhanlicheng-introduction">{text}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div ref={targetMediaRef}>
        <MediaPublicity />
      </div>
      <Footer
        scrollToCulture={scrollToCulture}
        scrollToRongyu={scrollToRongyu}
        scrollToMedia={scrollToMedia}
      />
    </div>
  );
};

export default CompanyProfile;
