import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/aiVoice.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarousel from "../productsComponents/ProductCarousel.tsx";
import { useScrollToTop } from "../../../App.tsx";

const AIVoice: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "AI voice negotiation robot",
    text2: "",
    text3: "AI语音协商机器人",
    btnItem: [
      {
        key: 0,
        text: "客户意图识别",
      },
      {
        key: 1,
        text: "模块化系统",
      },
      {
        key: 2,
        text: "意图识别算法",
      },
      {
        key: 3,
        text: "个性化服务",
      },
    ],
  };
  const techData = [
    {
      image: "./images/products/human-brain.svg",
      text1: "客户意图识别",
      text2:
        "采用先进AI模型，准确识别语音，实现流畅对话交流，帮助访客更好理解展品价值。",
    },
    {
      image: "./images/products/puzzle.svg",
      text1: "模块化系统",
      text2: "采用模块化设计，支持高效安装和快速部署，适用于多种室内外场景。",
    },
    {
      image: "./images/products/molecule.svg",
      text1: "意图识别算法",
      text2:
        "通过模块化评分和Softmax柔性输出，动态适应场景，提供智能交互体验。",
    },
    {
      image: "./images/products/sparkle-5.svg",
      text1: "个性化服务",
      text2: "提供个性化的推荐和咨询服务，增强客户体验并提高服务效率。",
    },
  ];
  const imageData = [
    "ai-voice-product-carousel-container1",
    "ai-voice-product-carousel-container2",
  ];

  const contentData = [
    "客户回访与满意度调查：在回访和调查过程中，AI客服助手能够以自然的互动方式收集客户反馈，提升客户参与度并提供深度分析，帮助企业优化服务",
    "电话营销：AI客服助手可通过多轮对话引导客户了解产品信息，识别客户兴趣点，提升销售转化率，同时提供实时数据分析，优化营销策略",
  ];

  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container ai-voice-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height ai-voice-product">
        <div className="container1200 ai-voice-product-container">
          <div className="ai-voice-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              拟人实时交互——AI电话客服助手是一款基于人工智能技术的前沿虚拟客服解决方案，专为电话营销、客户回访等场景设计。通过模拟真人互动，该系统能够提供个性化的多轮对话服务，提升客户沟通体验并提高企业服务效率。AI客服助手运用先进的AI技术，以虚拟助手的形式进行客户引导和问题解答，实现自然、流畅的交流效果。
            </span>
          </div>
          <div className="ai-voice-product-right">
            <img src="./images/products/ai_voice_pro.webp" alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="ai-voice-product-back" />
      </div>
      <ProductDetailImage imageUrl="./images/products/ai_voice1.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarousel imageData={imageData} contentData={contentData} />
      <Footer />
    </div>
  );
};

export default AIVoice;
