import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../components/home/Home.tsx";
import CompanyProfile from "../components/companyProfile/CompanyProfile.tsx";
import CoreTechnology from "../components/coreTechnology/CoreTechnology.tsx";
import Products from "../components/products/Products.tsx";
import XiaoShu from "../components/products/productDetail/XiaoShu.tsx";
import AIPresenter from "../components/products/productDetail/AIPresenter.tsx";
import AIVoice from "../components/products/productDetail/AIVoice.tsx";
import AIEducational from "../components/products/productDetail/AIEducational.tsx";
import AICell from "../components/products/productDetail/AICell.tsx";
import BSD from "../components/products/productDetail/BSD.tsx";
import MBTI from "../components/products/productDetail/MBTI.tsx";
import Products1 from "../components/products/Products1.tsx";

export const router = createBrowserRouter([
  {
    path: "/index",
    element: <Home />,
  },
  {
    path: "/company_introduction",
    element: <CompanyProfile />,
  },
  {
    path: "/core_technology",
    element: <CoreTechnology />,
  },
  {
    path: "/products",
    element: <Products />,
  }, {
    path: "/products1",
    element: <Products1 />,
  },
  {
    path: "/xiao_shu",
    element: <XiaoShu />,
  },
  {
    path: "/ai_presenter",
    element: <AIPresenter />,
  },
  {
    path: "/ai_voice",
    element: <AIVoice />,
  },
  {
    path: "/ai_educational",
    element: <AIEducational />,
  },
  {
    path: "/ai_cell",
    element: <AICell />,
  },
  {
    path: "/bsd",
    element: <BSD />,
  },
  {
    path: "/mbti",
    element: <MBTI />,
  },
  {
    path: "*",
    element: <Navigate to={"/index"} replace />,
  },
]);
