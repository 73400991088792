import React from "react";
import "./footer.css";
import { useLocation, useNavigate } from "react-router-dom";

const Footer: React.FC = (props: {
  scrollToCulture?: () => void;
  scrollToRongyu?: () => void;
  scrollToMedia?: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollToCulture, scrollToRongyu, scrollToMedia } = props;
  return (
    <div className="background">
      <div className="container">
        <div className="detail">
          <div>
            <img
              src="./images/navHeader/logo.webp"
              alt="像素跃迁"
              className="logo"
            />
            {/* <div>
              <img
                src="./images/footer/phone.webp"
                alt="电话"
                className="phone"
              />
              <img
                src="./images/footer/weChat.webp"
                alt="微信"
                className="we-chat"
              />
            </div> */}
          </div>
          <div className="detail-box">
            <div className="detail-item gongsijieshao">
              <span>公司介绍</span>
              <button
                onClick={() => {
                  navigate("/company_introduction");
                }}
              >
                公司简介
              </button>
              <button
                onClick={() => {
                  navigate("/company_introduction");
                  if (location.pathname === "/company_introduction")
                    if (scrollToCulture) scrollToCulture();
                }}
              >
                企业文化
              </button>
              <button
                onClick={() => {
                  navigate("/company_introduction");
                  if (location.pathname === "/company_introduction")
                    if (scrollToRongyu) scrollToRongyu();
                }}
              >
                荣誉资质
              </button>
              <button
                onClick={() => {
                  navigate("/company_introduction");
                  if (location.pathname === "/company_introduction")
                    if (scrollToMedia) scrollToMedia();
                }}
              >
                媒体宣传
              </button>
            </div>
            <div className="detail-item hexinjishu">
              <span>核心技术</span>
              <button
                onClick={() => {
                  navigate("/core_technology");
                }}
              >
                AlphaOS数字人驱动引擎
              </button>
            </div>
            <div className="detail-item chanpinzhongxin">
              <span>产品中心</span>
              <button
                onClick={() => {
                  navigate("/products");
                }}
              >
                所有产品
              </button>
              <button
                onClick={() => {
                  navigate("/xiao_shu");
                }}
              >
                智慧家庭数智管家AI机器人——小数
              </button>
              <button
                onClick={() => {
                  navigate("/ai_presenter");
                }}
              >
                AI讲解员
              </button>
              <button
                onClick={() => {
                  navigate("/ai_voice");
                }}
              >
                AI语音协商机器人
              </button>
              <button
                onClick={() => {
                  navigate("/ai_educational");
                }}
              >
                AI教育智能体
              </button>
              <button
                onClick={() => {
                  navigate("/ai_cell");
                }}
              >
                良渚实验室AI细胞培养预测工程师
              </button>
            </div>
            <div className="detail-item qitahezuo">
              <span>其他合作</span>
              <button
                onClick={() => {
                  navigate("/bsd");
                }}
              >
                波司登创意系统平台
              </button>
              <button
                onClick={() => {
                  navigate("/mbti");
                }}
              >
                基于MBTI人格特征的智能车衣定制工具
              </button>
            </div>
          </div>
        </div>
        {/* <div className="input-container">
          <input type="text" placeholder="请输入您的联系方式" />
          <button>提交</button>
        </div> */}
        <div className={'anhao'}>
          <div className={'row'}>
            <img src="./images/footer/lab.webp" alt="" />
            <div className={'columline'}></div>
            <div>
              <p className='address'>
                地址：杭州市余杭区浙江大学校友企业总部经济园
                <br />
                微信公众号：像素跃迁
                <br />
                邮箱：support@paeleap.com
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className='anhao-copy'>
              <p> Copyright©2023 版权所有 杭州像素跃迁科技有限公司</p>
              <span >
                备案号：
                <a href="https://beian.miit.gov.cn">浙ICP备2022036123号-1</a>
              </span>
            </div>
          </div>
          <div
            className={'row'}
          >
            <img src="./images/footer/gongan.webp" alt="" style={{ marginRight: 'calc(10 / var(--auto-width))' }} />
            <p >
              <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016879">
                浙公网安备33011002016879号
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
