import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/xiaoshu.css";
import ProductCarousel from "../productsComponents/ProductCarousel.tsx";
import Footer from "../../footer/Footer.tsx";
import { useScrollToTop } from "../../../App.tsx";

const XiaoShu: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "Smart Home Digital Butler AI",
    text2: "Robot - Xiao Shu",
    backImage: "xiaoshu_header.webp",
    text3: "智慧家庭数智管家AI机器人——小数",
    btnItem: [
      {
        key: 0,
        text: "GPTCall大模型驱动",
      },
      {
        key: 1,
        text: "全息虚拟形象",
      },
      {
        key: 2,
        text: "多层次情感交互",
      },
    ],
  };
  const techData = [
    {
      image: "./images/products/microchip-ai.svg",
      text1: "GPTCall大模型驱动",
      text2:
        "采用大语言模型实现智能对话功能，包括理解上下文、自然语言识别、推理分析等。",
    },
    {
      image: "./images/products/virtual.svg",
      text1: "全息虚拟形象",
      text2:
        "结合全息投影与3D技术，提供高度仿真、互动性强的虚拟助手形象，增添趣味性。",
    },
    {
      image: "./images/products/heartbeat.svg",
      text1: "多层次情感交互",
      text2: "通过分析情绪波动实现情感关怀，提高用户体验。",
    },
  ];
  const imageData = [
    "xiaoshu-product-carousel-container1",
    "xiaoshu-product-carousel-container2",
  ];

  const contentData = [
    "日常陪伴与提醒：提供天气查询、健康监控、事项提醒等日常功能，适应用户需求",
    "社区服务联动：整合周边社区网络，帮助老年用户快捷获取本地服务信息",
  ];
  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container xiaoshu-header-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height xiaoshu-product">
        <div className="xiaoshu-product-container">
          <div className="xiaoshu-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              小数是一款与华数中广联合研发的智慧家庭管家AI全息仓。该产品采用全息仓结构+AI虚拟人的设计，为老年人居家养老场景下的各项服务。作为新一代智慧家庭的终端入口，可以为老年人提供接入数字生活的捷径，为消除老年人数字鸿沟问题提供一种可能性，产品作为典型案例已被《人民日报》要闻头条报道。
            </span>
          </div>
          <div className="xiaoshu-product-right">
            <img src="./images/products/xiaoshu_image1.webp" alt="" />
          </div>
        </div>
        <img
          src="./images/products/product_back.webp"
          alt=""
          className="ai-xiaoshu-product-back"
        />
      </div>
      <ProductDetailImage imageUrl="./images/products/xiaoshu_image.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarousel imageData={imageData} contentData={contentData} />
      <Footer />
    </div>
  );
};

export default XiaoShu;
