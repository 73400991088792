import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/aiCell.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarouselItem from "../productsComponents/ProductCarouselItem.tsx";
import { useScrollToTop } from "../../../App.tsx";

const AICell: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "AI Cell Culture Prediction",
    text2: "Engineer",
    text3: "AI细胞培养预测工程师",
    btnItem: [
      {
        key: 0,
        text: "深度学习",
      },
      {
        key: 1,
        text: "深度学习",
      },
      {
        key: 2,
        text: "无人干预",
      },
      {
        key: 3,
        text: "实时监测",
      },
      {
        key: 4,
        text: "AI智能算法",
      },
    ],
  };
  const techData = [
    {
      image: "./images/products/book-open.svg",
      text1: "深度学习",
      text2: "使用先进的深度学习技术提升分析精度。",
    },
    {
      image: "./images/products/search-ai.svg",
      text1: "精准预测",
      text2: "提供高精度的自动化预测能力。",
    },
    {
      image: "./images/products/user-ban.svg",
      text1: "无人干预",
      text2: " 自动化流程无人工干预，减少人为误差。",
    },
    {
      image: "./images/products/eye-recognition.svg",
      text1: "实时监测",
      text2: "实时收集细胞状态数据，保证数据准确性。",
    },
    {
      image: "./images/products/molecule.svg",
      text1: "AI智能算法",
      text2: "使用AI算法进行数据驱动的精准模型分析。",
    },
  ];
  const itemData = {
    title: "cell",
    text: "实验室细胞培养: 通过自动化系统进行细胞培养和状态分析，适合科研实验室",
  };
  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container ai-cell-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height ai-cell-product">
        <div className="ai-cell-product-container">
          <div className="ai-cell-product-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              良渚实验室AI细胞培养预测工程师是一款通过先进的人工智能算法，AI预测功能能够对实验室的细胞培养情况进行精准预测和实时检测，保障细胞存活率并提升实验效率的智能产品。提供实时检测、自动化预测、细胞状态分析、数据分析模型、实验室设备链接等功能。
            </span>
          </div>
          <div className="ai-cell-product-right">
            <img src="./images/products/ai_cell_pro.webp" alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="ai-cell-product-back" />
      </div>
      <ProductDetailImage imageUrl="./images/products/ai_cell_image1.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarouselItem item={itemData} />
      <Footer />
    </div>
  );
};

export default AICell;
