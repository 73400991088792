import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/navHeader.css";

const NavHeaders: React.Fc = (props: {
  scrollToCulture?: () => void;
  scrollToRongyu?: () => void;
  scrollToDev?: () => void;
  scrollToMedia?: () => void;
}) => {
  const { scrollToCulture, scrollToRongyu, scrollToDev, scrollToMedia } = props;

  const navHeaderData = [
    {
      index: 0,
      title: "首页",
      route: "/index",
    },
    {
      index: 1,
      title: "公司介绍",
      route: "/company_introduction",
      icon: true,
      scrollToCulture: scrollToCulture,
      scrollToRongyu: scrollToRongyu,
      scrollToDev: scrollToDev,
      scrollToMedia: scrollToMedia,
    },
    {
      index: 2,
      title: "核心技术",
      route: "/core_technology",
    },
    {
      index: 3,
      title: "产品中心",
      route: "/products",
      icon: true,
    },
  ];

  return (
    <div className="nav">
      <nav className="container">
        <img
          src="./images/navHeader/logo.webp"
          alt="logo"
          className="logo-img"
        />
        <div className="menu">
          {navHeaderData.map((item, index) => {
            return (
              <div key={index}>
                <NavItem item={item} index={index} />
              </div>
            );
          })}
        </div>
      </nav>
    </div>
  );
};

const NavItem = (props: {
  item: {
    title: string;
    route: string;
    icon?: boolean;
    scrollToCulture?: any;
    scrollToRongyu?: any;
    scrollToDev?: any;
    scrollToMedia?: any;
  };
  index: number;
}) => {
  const {
    title,
    route,
    icon,
    scrollToCulture,
    scrollToRongyu,
    scrollToDev,
    scrollToMedia,
  } = props.item;
  const navigate = useNavigate();
  const location = useLocation();

  const CompanyItem = () => {
    return (
      <div className="hover-container nav-company-container">
        <button
          onClick={() => {
            navigate("/company_introduction");
          }}
        >
          公司简介
        </button>
        <button
          onClick={() => {
            navigate("/company_introduction");
            if (location.pathname === "/company_introduction")
              scrollToCulture();
          }}
        >
          企业文化
        </button>
        <button
          onClick={() => {
            navigate("/company_introduction");
            if (location.pathname === "/company_introduction") scrollToRongyu();
          }}
        >
          荣誉资质
        </button>
        <button
          onClick={() => {
            navigate("/company_introduction");
            if (location.pathname === "/company_introduction") scrollToDev();
          }}
        >
          发展历程
        </button>
        <button
          onClick={() => {
            navigate("/company_introduction");
            if (location.pathname === "/company_introduction") scrollToMedia();
          }}
        >
          媒体宣传
        </button>
        <img src="./images/navHeader/ellipse.webp" alt="background"></img>
      </div>
    );
  };
  const CoreItem = () => {
    return (
      <div className="hover-container nav-core-container">
        <button
          onClick={() => {
            navigate("/core_technology");
          }}
        >
          AlphaOS数字人驱动引擎
        </button>
        <img src="./images/navHeader/ellipse.webp" alt="background"></img>
      </div>
    );
  };
  const ProductsItem = () => {
    return (
      <div className="hover-container nav-products-container">
        <div>
          <button
            onClick={() => {
              navigate('/products');
            }}
          >
            所有产品
          </button>
          <button
            onClick={() => {
              navigate("/xiao_shu");
            }}
          >
            智慧家庭数智管家AI机器人——小数
          </button>
          <button
            onClick={() => {
              navigate("/ai_presenter");
            }}
          >
            AI讲解员
          </button>
          <button
            onClick={() => {
              navigate("/ai_voice");
            }}
          >
            AI语音协商机器人
          </button>
          <button
            onClick={() => {
              navigate("/ai_educational");
            }}
          >
            AI教育智能体
          </button>
          <button
            onClick={() => {
              navigate("/ai_cell");
            }}
          >
            良渚实验室AI细胞培养预测工程师
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              navigate('/products1');
            }}
          >
            其他合作
          </button>
          <button
            onClick={() => {
              navigate("/bsd");
            }}
          >
            波司登创意系统平台
          </button>
          <button
            onClick={() => {
              navigate("/mbti");
            }}
          >
            基于MBTI人格特征的智能车衣定制工具
          </button>
        </div>
        <img src="./images/navHeader/ellipse.webp" alt="background"></img>
      </div>
    );
  };

  return (
    <div className="nav-item">
      <button
        onClick={() => {
          navigate(route)
        }}
      >
        {title}
        {route === location.pathname ||
          ([
            "/xiao_shu",
            "/ai_presenter",
            "/ai_voice",
            "/ai_educational",
            "/ai_cell",
            "/bsd",
            "/mbti",
            "/products1"
          ].includes(location.pathname) &&
            title === "产品中心") ? (
          <img
            src="./images/navHeader/navHeader_act.webp"
            alt=""
            className="active-index"
          />
        ) : null}
      </button>

      {icon ? (
        <img
          src="./images/common/down_arrow.webp"
          alt=""
          className="arrow-img"
        ></img>
      ) : null}
      {title !== "首页" ? (
        <div>
          {title === "公司介绍" ? (
            <CompanyItem />
          ) : title === "核心技术" ? (
            <CoreItem />
          ) : (
            <ProductsItem />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default NavHeaders;
