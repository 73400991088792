import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/MBTI.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarousel from "../productsComponents/ProductCarousel.tsx";
import { useScrollToTop } from "../../../App.tsx";

const MBTI: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "An MBTI-Based Smart Car",
    text2: "Cover Customization Tool.",
    text3: "基于MBTI人格特征的智能车衣定制工具",
    btnItem: [
      {
        key: 0,
        text: "MBTI人格分析",
      },
      {
        key: 1,
        text: "智能推荐系统",
      },
      {
        key: 2,
        text: "个性化设计",
      },
    ],
  };

  const techData = [
    {
      image: "./images/products/human-gear.webp",
      text1: "MBTI人格分析",
      text2:
        "利用MBTI人格评估工具，精准识别用户的个性和偏好，为车衣设计提供科学依据。",
    },
    {
      image: "./images/products/sparkle-5.svg",
      text1: "智能推荐系统",
      text2:
        "根据用户MBTI类型，系统自动推荐符合其个性车衣设计方案，提高定制效率和准确性。",
    },
    {
      image: "./images/products/brush-sparkle.svg",
      text1: "个性化设计",
      text2:
        "用户可以根据自己喜好和需求，对推荐设计方案进行调整和优化，实现个性化定制。",
    },
  ];
  const imageData = [
    "mbti-product-carousel-container1",
    "mbti-product-carousel-container2",
    "mbti-product-carousel-container3",
  ];

  const contentData = [
    "个人车主：为追求个性化的车主提供独一无二的车衣定制服务，彰显其个性和品味",
    "汽车美容店：作为汽车美容店的增值服务项目，吸引更多客户，提升店铺竞争力",
    "汽车厂商：为汽车厂商提供定制化的车衣设计服务，满足其特定车型或市场需求。",
  ];
  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container MBTI-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <div className="height product-introduction-product MBTI-prop-back">
        <div className="product-introduction-container">
          <div className="product-introduction-left">
            <span>Product Introduction</span>
            <span>产品介绍</span>
            <span>
              智能车衣定制工具是一款创新的智能车衣定制工具，它基于MBTI（Myers-Briggs
              Type
              Indicator）人格特征，为车主提供个性化的车衣设计方案。该工具通过收集和分析大量数据，发现不同MBTI类型的人对于汽车的外观、颜色、图案等方面有着独特的偏好。可以根据用户的MBTI人格特征，为其推荐最符合其个性和喜好的车衣设计方案。用户只需简单输入自己的MBTI类型，系统就会自动为其生成多种设计方案，供用户选择和调整。
            </span>
          </div>
          <div className="product-introduction-img">
            <img src={"./images/products/MBTI_pro.webp"} alt="" />
          </div>
        </div>
        <img src="./images/products/product_back.webp" alt="" className="product-introduction-back" />
      </div>
      <ProductDetailImage imageUrl="./images/products/MBTI_image1.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarousel imageData={imageData} contentData={contentData} />
      <Footer />
    </div>
  );
};

export default MBTI;
