import React from "react";
import "../css/productsDetailHeader.css";

const ProductsDetailHeader: React.FC = (props: { headerData: any }) => {
  const { text1, text2, text3, btnItem } = props.headerData

  return (
    <div className="container1200 xiaoshu">
      <span className="xiaoshu-smart">{text1}</span>
      {
        text2 ? <span className="xiaoshu-smart">{text2}</span> : null
      }

      <span className="xiaoshu-zhihui">{text3}</span>
      <div className="xiaoshu-buttons">
        {btnItem.map((item: any, index: number) => {
          return <button key={index}>{item.text}</button>
        })}
      </div>
      <div className="xiaoshu-explore">
        <div>
          <img src="./images/products/down_arrow.webp" alt="" />
        </div>
        <span>Explore</span>
      </div>
    </div>
  );
};

export default ProductsDetailHeader;
