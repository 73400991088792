import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";

const App = () => {
  return <div style={{ width: "100%", margin: "0 auto" }}></div>;
};
export function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

export default App;
