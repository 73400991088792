import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "../css/mediaPublicity.css";

const MediaPublicity = () => {
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "央媒关注！未来科技城优质创业项目火出圈",
      children: (
        <div className="media-collapse">
          <p className="ant-collapse-p">
            「&nbsp;&nbsp;&nbsp;智慧家庭数智管家AI机器人」亮相2023康博会，再次引发关注。作为未来智慧家庭生活的核心技术，这款AI机器人融合了语音识别、情感分析、智能家居控制等多项先进技术，致力于为用户提供更加便捷、高效、个性化的生活体验。
          </p>
          <img src="./images/company/news.webp" alt="" />
        </div>
      ),
    },
    {
      key: "2",
      label: "华数受邀参展深圳文博会",
      children: (
        <div className="media-collapse">
          <p className="ant-collapse-p">
            「&nbsp;&nbsp;&nbsp;智慧家庭数智管家AI机器人」在展会现场吸引了大批参观者驻足体验。机器人可以通过语音指令进行家电控制、健康监测以及日常生活辅助，让家庭生活更智能、更贴心。未来，这一项目有望在更多领域得到应用，为智慧城市建设注入新动力。
          </p>
          <img src="./images/company/news1.webp" alt="" />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span>
          让家变得更「&nbsp;&nbsp;&nbsp;聪明」，经济新方位•衣食住行看转型
        </span>
      ),
      children: (
        <div className="media-collapse">
          <p className="ant-collapse-p">
            小数是一款智慧家庭管家AI全息仓。采用全息仓结构+AI虚拟人的设计，服务老年人居家养老场景。作为新一代智慧家庭的终端入口，可以为老年人提供接入数字生活的捷径，为消除老年人数字鸿沟问题提供一种可能性，本产品作为典型案例已经被《人民日报》要闻头条报道。
          </p>
          <img src="./images/company/news2.webp" alt="" />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <span>
          灿若星辰 | 18级ITPer陈小雨与华数集团合作研发项目「&nbsp;&nbsp;&nbsp;智
          <br></br>
          慧家庭数智管家AI机器人」亮相2023康博会
        </span>
      ),
      children: (
        <div className="media-collapse">
          <p className="ant-collapse-p">
            创始人陈小雨表示，这一项目的研发经历了多次技术迭代和优化，团队始终以用户需求为导向，通过数据分析与算法升级，不断提升产品的智能化程度。此次在康博会的亮相，不仅展示了国内AI技术的最新进展，也为智慧家庭领域的未来发展提供了更多可能性。
          </p>
          <img src="./images/company/news3.webp" alt="" />
        </div>
      ),
    },
  ];
  return (
    <div style={{ paddingTop: "calc(137 / var(--auto-width))", background: "#010101" }}>
      <div className="container media-publicity-container">
        <div className="media-publicity-line"></div>
        <span className="media-publicity-title">媒体宣传</span>
      </div>
      <div className="container">
        <Collapse
          type="background"
          accordion
          items={items}
          expandIcon={() => {
            return <img src="./images/company/down_76.webp" alt=""></img>;
          }}
          expandIconPosition="end"
          showArrow={false}
        ></Collapse>
      </div>
    </div>
  );
};

export default MediaPublicity;
