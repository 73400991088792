import React from "react";
import "../css/productCarouselItem.css";

const ProductCarouselItem: React.FC = (props: {
  item: { title: string; text: string };
}) => {
  const { title, text } = props.item;
  const classNames =
    title !== "educational"
      ? title === "cell"
        ? "product-carousel-item1"
        : "product-carousel-item2"
      : "product-carousel-item3";
  return (
    <div className={`height product-carousel-item ${classNames}`}>
      <div className="product-carousel-item-span">
        <span className="product-carousel-item-text">
          Application scenarios
        </span>
        <div className="product-carousel-item-div">应用场景</div>
      </div>
      <span className="product-carousel-item-text product-carousel-item-hover">
        {text}
      </span>
    </div>
  );
};

export default ProductCarouselItem;
