import React from "react";
import "../css/productIntroduction.css";

const ProductIntroduction: React.FC = (props: { proData: any }) => {
  const { text, imageUrl } = props.proData;
  return (
    <div className="height product-introduction-product">
      <div className="product-introduction-container">
        <div className="product-introduction-left">
          <span>Product Introduction</span>
          <span>产品介绍</span>
          <span>{text}</span>
        </div>
        <div className="product-introduction-img">
          <img src={`./images/products/${imageUrl}`} alt="" />
        </div>
      </div>
      <img src="./images/products/product_back.webp" alt="" className="product-introduction-back" />
    </div>
  );
};

export default ProductIntroduction;
