import React from "react";
import NavHeader from "../../navHeader/NavHeader.tsx";
import ProductsDetailHeader from "../productsComponents/ProductsDetailHeader.tsx";
import ProductIntroduction from "../productsComponents/ProductIntroduction.tsx";
import ProductDetailImage from "../productsComponents/ProductDetailImage.tsx";
import ProductTechnology from "../productsComponents/ProductTechnology.tsx";
import "../css/BSD.css";
import Footer from "../../footer/Footer.tsx";
import ProductCarouselItem from "../productsComponents/ProductCarouselItem.tsx";
import { useScrollToTop } from "../../../App.tsx";

const BSD: React.FC = () => {
  useScrollToTop();
  const headerData = {
    text1: "BSD Creative System Platform",
    text2: "",
    text3: "波司登创意系统平台",
    btnItem: [
      {
        key: 0,
        text: "多功能设计模块",
      },
      {
        key: 1,
        text: "高效数据管理",
      },
      {
        key: 2,
        text: "智能创意生成",
      },
    ],
  };
  const proData = {
    text: "智能创意系统是一款面向设计人员的综合性创意平台，提供从灵感图库到智能成衣、图案生成等全流程设计支持。用户可以通过该系统进行设计资产管理、花型生成和实景预览等操作，满足多样化的设计需求。",
    imageUrl: "BSD_pro.webp",
  };
  const techData = [
    {
      image: "./images/products/puzzle.svg",
      text1: "多功能设计模块",
      text2: "系统整合灵感图库、智绘花型、智能成衣等功能，提升设计效率。",
    },
    {
      image: "./images/products/sitemap.svg",
      text1: "高效数据管理",
      text2: "系统提供数据浏览、模型库、设计资产和个人资产等管理功能。",
    },
    {
      image: "./images/products/magic-wand.svg",
      text1: "智能创意生成",
      text2:
        "AI算法实现灵感图稿自动生成、灵感裂变和风格迁移，帮助设计人员快速构思创意。",
    },
  ];
  const itemData = {
    title: "bsd",
    text: "时尚设计与服装搭配：系统为服装设计师提供从灵感生成到成衣预览的一体化解决方案，帮助实现快速设计和展示。",
  };
  return (
    <div style={{ background: "black" }}>
      <NavHeader />
      <div className="xiaoshu-container BSD-container">
        <ProductsDetailHeader headerData={headerData} />
      </div>
      <ProductIntroduction proData={proData} />
      <ProductDetailImage imageUrl="./images/products/BSD_image1.webp" />
      <ProductTechnology techData={techData} />
      <ProductCarouselItem item={itemData} />
      <Footer />
    </div>
  );
};

export default BSD;
