import React from "react";
import "../css/companyCarousel.css";

const CompanyCarousel = () => {
  const itemData = [
    {
      image1: "./images/company/reddot.webp",
      image2: "./images/company/international.webp",
    },
    {
      image1: "./images/company/design_award_2020.webp",
      image2: "./images/company/design.webp",
    },
    {
      image1: "./images/company/reddot.webp",
      image2: "./images/company/international.webp",
    },
    {
      image1: "./images/company/design_award_2020.webp",
      image2: "./images/company/design.webp",
    },
    {
      image1: "./images/company/reddot.webp",
      image2: "./images/company/international.webp",
    },
    {
      image1: "./images/company/design_award_2020.webp",
      image2: "./images/company/design.webp",
    },
  ];

  const Item = (props: {
    itemData: { image1: string; image2: string };
    index: number;
  }) => {
    const { image1, image2 } = props.itemData;
    const { index } = props;
    return (
      <div key={index} className="rongyuzizhi-box">
        <img
          src={image1}
          alt=""
          className={[
            index % 2 !== 0
              ? "rongyuzizhi-box2-image1"
              : "rongyuzizhi-box1-image1",
          ]}
        />
        <img
          src={image2}
          alt=""
          className={[
            index % 2 !== 0
              ? "rongyuzizhi-box2-image2"
              : "rongyuzizhi-box1-image2",
          ]}
        />
      </div>
    );
  };

  return (
    <div className="rongyuzizhi">
      <div className="rongyuzizhi-scroll-content">
        {itemData.concat(itemData).map((item, index) => {
          return <Item itemData={item} index={index}></Item>;
        })}
      </div>
    </div>
  );
};

export default CompanyCarousel;
