import React from "react";
import "../css/carouselToRight.css";

const CarouselToRight: React.FC = (props: { data }) => {
  return (
    <div className="home-carousel2">
      <div className="scroll-content2">
        {props.data.map((imageUrl, index) => (
          <div key={index} className="scroll-item2">
            <img src={`./images/home/${imageUrl}`} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselToRight;
